<template>
  <!-- 电梯导航 -->
  <div class="azh-sidebar">
    <ul>
      <li class="wdxx" @click="$router.push({ path: '/member/message' })">
        <div></div>
        <!-- <img src="@/assets/home/xx.png" alt /> -->
        <p>我的消息</p>
      </li>
      <li class="zxkf">
        <div><a
            style="color: #000;"
            href="https://2zhpc.sobot.com/chat/pc/v2/index.html?sysnum=3aaba5ecd0314f0bb6df47f95fdcde8c&channelid=2"
            target="_blank"
            ></a
          ></div>
        <!-- <img src="@/assets/home/kf.png" alt /> -->
        <p>
          <a
            style="color: #000"
            href="https://2zhpc.sobot.com/chat/pc/v2/index.html?sysnum=3aaba5ecd0314f0bb6df47f95fdcde8c&channelid=2"
            target="_blank"
            >在线客服</a
          >
        </p>
      </li>
      <li class="shbz" @click="$router.push({ path: '/novice' })">
        <div></div>
        <p>上号帮助</p>
      </li>
      <li class="xzsh" @click="$router.push({ path: '/download' })">
        <div></div>
        <!-- <img src="@/assets/home/xz.png" alt /> -->
        <p>下载上号器</p>
      </li>
      <li class="hddb" @click="toTop">
        <div></div>
        <!-- <img class="db" src="@/assets/home/hddb.png" alt /> -->
        <p>回到顶部</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss">
.azh-sidebar {
  position: fixed;
  right: 50%;
  top: 360px;
  margin-right: -720px;
  width: 80px;
  height: 390px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // border: 1px solid #ebeef5;
  background-color: #fff;
  border-radius: 9px;
  z-index: 99;
  ul {
    li {
      display: flex;
      flex-flow: column;
      padding-top: 10px;
      box-sizing: border-box;
      justify-content: space-around;
      align-items: center;
      height: 75px;
      font-size: 12px;
      div {
        width: 38px;
        height: 38px;
        cursor: pointer;
      }
      .db {
        width: 20px;
        height: 20px;
      }
    }
    .wdxx div{
      background: url(../../assets/home/xx.png) no-repeat center center;
    }
    .zxkf div{
       background: url(../../assets/home/kf.png) no-repeat center center;
    }
    .zxkf div a{
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .shbz div{
      background: url(../../assets/home/sh.png) no-repeat center center;
    }
    .xzsh div{
      background: url(../../assets/home/xz.png) no-repeat center center;
    }
    .hddb div{
      background: url(../../assets/home/hddb.png) no-repeat center center;
    }
    .wdxx:hover div{
      background: url(../../assets/home/xx2.png) no-repeat center center;
    }
    .zxkf:hover div{
      background: url(../../assets/home/kf2.png) no-repeat center center;
    }
    .shbz:hover div{
      background: url(../../assets/home/sh2.png) no-repeat center center;
    }
    .xzsh:hover div{
      background: url(../../assets/home/shq2.png) no-repeat center center;
    }
    .hddb:hover div{
      background: url(../../assets/home/hddb2.png) no-repeat center center;
    }
  }
}
</style>