<!--  -->
<template>
  <div class="footers"><span v-if="ret.company">{{ ret.company }} </span><span v-if="ret.icp">经营许可证：{{ ret.icp }} </span><span v-if="ret.number">网站备案号：{{ ret.number }} </span><span v-if="ret.cert">增值业务许可证：{{ ret.cert }} </span><span v-if="ret.filing">鄂公网安备：{{ ret.filing }}</span>
  </div>
</template>

<script>
export default {
  name: 'footers',
  data() {
    return {
      ret: {}
    }
  },
  mounted: function () {
    let _ret = sessionStorage.getItem('ret')
    if (_ret !== '' && _ret !== null) this.ret = JSON.parse(_ret)
  }
}
</script>

<style lang="scss" scoped>
.footers {
  font-size: 12px;
  color: #989aa2;
  text-align: center;
}
</style>
