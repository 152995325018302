<!--  -->
<template>
 <div class="layout">
   <el-container>
      <el-header>
        <header-top></header-top>
      </el-header>
      <el-main>
        <slot></slot>
        <bottoms></bottoms>
      </el-main>
      <el-footer>
        <footer-botmmon></footer-botmmon>
      </el-footer>
    </el-container>
 </div>
</template>
 
<script>
import headerTop from "../components/common/header";
import footerBotmmon from "../components/common/footer";
import bottoms from "../components/bottom";
export default {
 name: 'layout',
 data () {
 return {
   }
  },
  components: {
    headerTop,
    footerBotmmon,
    bottoms,
  },
}
</script>
 
<style lang='scss' scoped >
 .el-footer,
.el-header {
  background-color: #000;
  height: 60px;
  line-height: 60px;
}
.el-main {
  background-color: #fff;
  padding: 0;
}
</style>