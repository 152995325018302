<!--  -->
<template>
 <div class="bottoms">
     <div class="contant">
         <div class="left">
             <img src="../assets/images/bottom/logo-db.png" alt="">
             <p>爱玩就上爱租号</p>
         </div>
         <div class="right">
             <ul>   
                 <li class="frister">新手上路</li>
                   <router-link tag="li"  :to="{name: 'novice'}">新手必读</router-link>
                   <router-link tag="li"  :to="{name: 'center'}">注册及认证</router-link>
                   <router-link tag="li"  :to="{name: 'center'}">如何租用账号</router-link>
             </ul>
              <ul>   
                 <li>贴心服务</li> 
                 <router-link tag="li"  :to="{name: 'center'}">帮助中心</router-link>
                  <router-link tag="li"  :to="{name: 'service'}">服务协议</router-link>
                  <router-link tag="li"  :to="{name: 'private'}">隐私政策</router-link>
                 <!-- <li></li>  -->
             </ul>
              <ul>   
                 <li>关于爱租号</li>  
                 <router-link tag="li"  :to="{name: 'contact'}">联系我们</router-link>
                 <li><a class="color-a" href="https://2zhpc.sobot.com/chat/pc/v2/index.html?sysnum=3aaba5ecd0314f0bb6df47f95fdcde8c&channelid=2" target="_blank">联系客服</a></li>
             </ul>
         </div>
     </div>
 </div>
</template>
 
<script>
export default {
 name: 'bottom',
 data () {
 return {
   }
  }
}
</script>
 
<style lang='scss' scoped >
 .bottoms{
     width: 100%;
     height: 280px;
     background-color: #3D3F45;
     .contant{
         margin: 0 auto;
         width: 1200px;
         display: flex;
         height: 100%;
         padding: 50px 0 0;
         justify-content: space-between;
         .left{
             img{
                 width: 182px;
                 height: 45px;
             }
             p{
                 font-size:18px;
                 color: #989AA2;
                 text-align: right;
                 margin-top: 15px;
             }
         }
         .right{
             display: flex;
             ul{
                 margin-left: 142px;
                 li{
                     font-size: 14px;
                     color: #989AA2;
                     text-align: right;
                     line-height: 30px;
                     cursor: pointer;
                 }
                 li:nth-child(1){
                     font-size: 16px;
                 } 
             }
         }
     }
 }
.color-a{
    color: #989AA2;
}
</style>