<!--  -->
<template>
 <div class="headers" style="height:40px;">
     <div class="title">您好，欢迎来到爱租号~</div>
     <div class="nav">
        <el-menu
            class="el-menu-demo"
            mode="horizontal"
            text-color="#989BA2"
             active-text-color="#989BA2"
          >
            <el-menu-item v-if="avatar">
            <el-avatar size="small" :src="avatar"></el-avatar>
            {{nick_name}}
            </el-menu-item>
            <el-menu-item v-if="avatar" @click="goOut">退出</el-menu-item>
            <el-menu-item v-else @click="$router.push('/login')">请登录</el-menu-item>
            <router-link tag="el-menu-item"  :to="{name: 'myOrder'}">我的订单</router-link>
            <el-menu-item @click="$router.push({path:'/member'})">个人中心</el-menu-item>
            <el-submenu index="4" popper-class="submenu-item">
                <template slot="title">帮助中心 <i class="el-icon-caret-bottom"></i></template>
                <router-link tag="el-menu-item"  :to="{name: 'center'}">新手帮助</router-link>
                <router-link tag="el-menu-item"  :to="{name: 'novice'}">新手必读</router-link>
                <router-link tag="el-menu-item"  :to="{name: 'center'}">注册及认证</router-link>
                <router-link tag="el-menu-item"  :to="{name: 'center'}">如何租用账号</router-link>
                <router-link tag="el-menu-item"  :to="{name: 'protocol'}">爱租号仲裁规则</router-link>
                <el-menu-item ><el-button round plain size="mini" v-if="false">用户反馈</el-button></el-menu-item>
            </el-submenu>
            <el-submenu index="5">
                <template slot="title">关于我们 <i class="el-icon-caret-bottom"></i></template>
                <router-link tag="el-menu-item"  :to="{name: 'about'}">关于我们</router-link>
                <router-link tag="el-menu-item"  :to="{name: 'contact'}">联系我们</router-link>
                <router-link tag="el-menu-item"  :to="{name: 'duty'}">免责声明</router-link>
                 <el-menu-item > <a class="color_a" :href="url" @click="kefu" target="_blank">联系客服</a></el-menu-item>
            </el-submenu>
            </el-menu>
     </div>
 </div>
</template>
 
<script>
export default {
 name: 'headers',
 data () {
 return {
     avatar: '',
     nice_name:'',
     url:''
   }
  },
  mounted() {
      this.avatar = sessionStorage.getItem('avatar')
      this.nick_name = sessionStorage.getItem('nick_name')
  },
  methods:{
      goOut() {
        sessionStorage.removeItem('avatar')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user_id')
        sessionStorage.removeItem('nick_name')
        sessionStorage.removeItem('ext_cash')
        sessionStorage.removeItem('isLogin')
        this.$router.replace('/login')
      },

        kefu() {
          this.url = "https://2zhpc.sobot.com/chat/pc/v2/index.html?sysnum=3aaba5ecd0314f0bb6df47f95fdcde8c&channelid=2"
        }
  }
}
</script>
 
<style lang='scss' >
 .headers{
     width: 1200px;
     margin: 0 auto;
     display: flex;
     align-items: center;
     justify-content: space-between;
     .title{
         font-size: 12px;
         color: #989BA2;
     }
     .nav{
         .el-menu{
            background-color:#292929;
            height: 40px;
            .el-menu-item{
            height: 40px;
            line-height: 40px;

            }
         }
     }
 }
 .el-submenu__title{
     height: 40px !important;
        line-height: 40px !important;

 }
 .el-header{
     height: 40px !important;
     background-color:#292929!important;
 }
//导航默认样式修改
.el-icon-arrow-down:before {
    content: "" !important;
}
.el-menu--horizontal>.el-submenu .el-submenu__title,
.el-menu--horizontal>.el-menu-item.is-active{
 border-bottom: 1px solid  #000 !important;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
//鼠标悬浮时，主菜单的样式
.el-submenu__title:focus,
.el-submenu__title:hover {
	color: #666 ;
    background: #000 ;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-submenu .el-submenu__title:focus {
    background-color: #292929;
    
}
//二次菜单悬浮样式
.el-menu--popup-bottom-start .el-menu-item:hover{
    color: #292929!important;
    font-size: 16px;
    font-weight: 600;
}
.el-menu--popup {
    min-width: 150px;
    text-align: center;
    border-radius: 6px;
    height: 40px;
}

.el-submenu__title {
    font-size: 12px;
    color: #303133;
    padding: 0 20px;
    cursor: pointer;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;
}
.el-menu-item {
    font-size: 12px;
    color: #303133;
    padding: 0 20px;
    cursor: pointer;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;
}
.color_a{
	color: #989BA2 ;
    &:hover{
     color: #000;
    font-size: 16px;
    font-weight: 600;
    }
}
</style>